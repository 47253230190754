<template>
  <div class="sidebar-settings">
    <aside class="sidebar-settings-aside">
      <div class="sidebar-settings-container">
        <div class="sidebar-settings-avatar group">
          <div
            v-if="portfolioPublished"
            class="sidebar-settings-avatar-link bg-gray-900 bg-opacity-0 group-hover:bg-opacity-50"
          >
            <DsExternalLink
              :href="portfolioUrl"
              target="_blank"
              class="hidden group-hover:block"
            >
              <ExternalLinkSolidIcon class="avatar-link-icon" />
            </DsExternalLink>
          </div>
          <DsAvatar
            :alt="fullName"
            :src="profileImage"
            class="mb-3 z-0"
            :populating="populating"
          />
        </div>
        <div class="sidebar-settings-creator-details">
          <div v-if="!populating" class="creator-name">
            {{ fullName }}
          </div>
          <DsSkeleton v-else class="w-full h-6" />
          <div v-if="!populating" class="creator-date">
            {{ memberSinceText }}
          </div>
          <DsSkeleton v-else class="w-3/4 h-5 mt-1" />
          <div class="creator-rank">
            <DsBadge
              v-if="!populating"
              id="sidebar-creator-level"
              :title="
                $t(`modules.settings.sidebarSettings.rating.level.${level}`)
              "
              theme-color="gray"
              size="sm"
              class="rounded-md"
            />
            <DsSkeleton v-else class="w-40 h-5" />
            <Translation
              v-if="!populating"
              keypath="modules.settings.sidebarSettings.activeJobs"
              tag="p"
              class="text-sm font-normal text-gray-500 pt-1"
            >
              <span class="font-medium text-gray-900">
                {{ activeJobs }}
              </span>
              <span class="font-medium text-gray-900">
                {{ maxJobs }}
              </span>
            </Translation>
            <DsSkeleton v-else class="w-44 h-5 mt-1" />
            <div class="flex items-center pt-1.5">
              <DsRatingStars
                v-if="!populating"
                id="sidebar-rating"
                :rating="rating"
                size="sm"
              />
              <DsSkeleton v-else class="w-24 h-5" />
              <span
                v-if="!populating"
                class="text-sm font-medium text-gray-900 ml-2"
              >
                {{ formattedRating }}
              </span>
              <DsSkeleton v-else class="w-6 h-5 ml-1" />
              <span
                v-if="!populating"
                class="text-sm font-normal text-gray-500 ml-1.5"
              >
                {{ ratingsText }}
              </span>
              <DsSkeleton v-else class="w-6 h-5 ml-1" />
            </div>
            <DsButton
              theme-color="blue"
              theme-display="link"
              class="ds-link-no-underline pt-3 text-sm font-normal"
              @click="onOpenModal"
              data-testid="modal-link-levels"
            >
              {{ $t(`modules.settings.sidebarSettings.linkLevels`) }}
            </DsButton>
          </div>
        </div>
        <div class="sidebar-settings-links">
          <nav>
            <SidebarSettingsItem
              :to="{ name: 'Account' }"
              route-name="Account"
              icon="UserSolidIcon"
              :title="$t('modules.settings.account.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'PortfolioSummary' }"
              route-name="PortfolioSummary"
              icon="PortfolioSolidIcon"
              :title="$t('modules.settings.portfolio.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'Reviews' }"
              route-name="Reviews"
              icon="StarSolidIcon"
              :title="$t('modules.settings.reviews.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'Wallet' }"
              route-name="Wallet"
              icon="MoneySolidIcon"
              :title="$t('modules.settings.wallet.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'PayoutMethod' }"
              route-name="PayoutMethod"
              icon="CardSolidIcon"
              :title="$t('modules.settings.payoutMethod.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'Certifications' }"
              route-name="Certifications"
              icon="BadgeSolidIcon"
              :title="$t('modules.settings.certifications.sidebarText')"
            >
              <template #title>
                <Translation
                  keypath="modules.settings.certifications.sidebarText"
                  tag="p"
                  class="text-sm group-hover:text-jb-gray-900"
                >
                  <span class="text-sm font-medium text-gray-900">
                    {{ certificates.approved }}
                  </span>
                  <span class="text-sm font-medium text-gray-900">
                    {{ certificates.total }}
                  </span>
                </Translation>
              </template>
            </SidebarSettingsItem>
            <SidebarSettingsItem
              :to="{ name: 'NotificationSettings' }"
              route-name="NotificationSettings"
              icon="BellSolidIcon"
              :title="$t('modules.settings.notifications.sidebarText')"
            />

            <SidebarSettingsItem
              v-if="false"
              :to="{ name: 'RequestAFeature' }"
              route-name="RequestAFeature"
              icon="BellSolidIcon"
              :title="$t('modules.settings.requestFeature.sidebarText')"
            />
            <DsButton
              theme-display="link"
              class="contact-link ds-link-no-underline group"
              @click="onOpenContactModal"
              data-testid="contact-support-sidebar-link"
            >
              <ContactSolidIcon
                class="contact-link-icon text-jb-gray-400 group-hover:text-jb-gray-900"
              />
              <span
                class="contact-link-text text-gray-500 group-hover:text-jb-gray-900"
              >
                {{ $t(`modules.settings.contact.sidebarText`) }}
              </span>
            </DsButton>
            <SidebarSettingsItem
              :to="{ name: 'AffiliateProgram' }"
              route-name="AffiliateProgram"
              icon="ReferralSolidIcon"
              :title="$t('modules.settings.affiliate.sidebarText')"
            />
          </nav>
        </div>
      </div>
    </aside>
    <CreatorLevelsModal
      :title="$t('modules.settings.portfolio.creatorLevels.modal.title')"
      ref="creatorLevelSidebar"
      id="creator-levels-sidebar-modal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Translation } from "vue-i18n";

import {
  DsAvatar,
  DsBadge,
  DsButton,
  DsExternalLink,
  DsModalHandler,
  DsRatingStars,
  DsSkeleton,
} from "@devsalsa/vue-core";

import CreatorLevelsModal from "@/modules/settings/components/account/CreatorLevelsModal.vue";
import SidebarSettingsItem from "@/modules/settings/components/SidebarSettingsItem.vue";

import ContactSolidIcon from "@/shared/components/icon/ContactSolidIcon.vue";
import ExternalLinkSolidIcon from "@/shared/components/icon/ExternalLinkSolidIcon.vue";

import AccountService from "@/core/modules/account/services/AccountService";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default defineComponent({
  name: "SidebarSettings",
  components: {
    DsExternalLink,
    DsSkeleton,
    DsAvatar,
    SidebarSettingsItem,
    DsRatingStars,
    DsButton,
    DsBadge,
    CreatorLevelsModal,
    ExternalLinkSolidIcon,
    Translation,
    ContactSolidIcon,
  },
  data() {
    return {
      populating: false,
      activeJobs: 0,
      certificates: {
        approved: 0,
        total: 0,
      },
    };
  },
  computed: {
    level(): number {
      return AccountHandler.accountInfo.value?.level || 1;
    },
    fullName(): string {
      return `${AccountHandler.accountInfo.value?.first_name || ""} ${
        AccountHandler.accountInfo.value?.last_name || ""
      }`;
    },
    signUpDate(): string {
      return this.$datetime.formatUnixTime(
        AccountHandler.accountInfo.value?.date_created || 0,
        "short"
      );
    },
    profileImage(): string {
      return AccountHandler.accountInfo.value?.profile_image || "";
    },
    rating(): number {
      return AccountHandler.accountInfo.value?.rating.account.avg_stars || 0;
    },
    ratings(): number {
      return AccountHandler.accountInfo.value?.rating.account.ratings || 0;
    },
    formattedRating(): string {
      return (
        AccountHandler.accountInfo.value?.rating.account.avg_stars.toFixed(1) ||
        ""
      );
    },
    maxJobs(): number {
      return AccountHandler.accountInfo.value?.max_jobs || 0;
    },
    ratingsText(): string {
      return this.$t("modules.settings.sidebarSettings.ratings", {
        total: this.ratings,
      });
    },
    memberSinceText(): string {
      return this.$t("modules.settings.sidebarSettings.memberSince", {
        signUpDate: this.signUpDate,
      });
    },
    portfolioPublished(): boolean {
      return AccountHandler.accountInfo.value?.profile_online || false;
    },
    portfolioUrl(): string {
      return `${import.meta.env.VITE_APP_WEBSITE_URL}/@${
        AccountHandler.accountInfo.value?.profile_handle
      }`;
    },
  },
  async created() {
    await this.populateAccount();
  },
  methods: {
    async populateAccount(): Promise<void> {
      this.populating = true;
      const creatorStats = await AccountService.getStats();
      this.activeJobs = creatorStats.active_jobs;
      this.certificates.approved = creatorStats.nr_certification_approved;
      this.certificates.total = creatorStats.nr_certifications;
      this.populating = false;
    },
    onOpenModal() {
      (
        this.$refs.creatorLevelSidebar as InstanceType<
          typeof CreatorLevelsModal
        >
      ).open();
    },
    onOpenContactModal() {
      DsModalHandler.open("contact-support-modal");
    },
  },
});
</script>

<style lang="scss" scoped>
.sidebar-settings {
  @apply h-full;

  &-aside {
    @apply xl:flex flex-col w-full xl:w-[264px] h-full xl:p-6 flex-shrink-0 z-0 xl:sticky top-0 xl:max-h-[calc(100vh-64px)] xl:h-auto;

    .sidebar-settings-container {
      @apply flex flex-col flex-grow overflow-y-auto;

      .sidebar-settings-creator-details {
        @apply flex flex-col pb-6 mb-6 border-gray-200 border-b mt-3;

        .creator-name {
          @apply text-base font-semibold text-gray-900;
        }

        .creator-date {
          @apply text-sm font-normal text-gray-500;
        }

        .creator-rank {
          @apply whitespace-nowrap overflow-hidden text-ellipsis pt-3;
        }
      }

      .sidebar-settings-links {
        @apply flex-grow flex flex-col;

        nav {
          @apply flex-1 space-y-4 pb-1;

          .contact-link {
            @apply hover:no-underline focus:no-underline  w-full text-left;

            .contact-link-icon {
              @apply h-5 w-5 inline-block mr-4;
            }

            .contact-link-text {
              @apply text-sm font-medium;
            }
          }
        }
      }
    }

    .sidebar-settings-avatar {
      @apply relative w-12 h-12 rounded-full;

      &-link {
        @apply absolute inset-0 z-10 flex items-center justify-center rounded-full;

        .avatar-link-icon {
          @apply h-6 w-6 fill-white;
        }
      }
    }
  }
}
</style>
