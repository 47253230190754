import { ApiService } from "@/core/shared/services/ApiService";
import type {
  Category,
  Country,
  State,
  Gender,
  UgcCertification,
  MinSalaryData,
  MinSalaryResponse,
} from "@/services/AssetsService.types";
import type { LevelRequirements } from "@/services/AssetsService.types";

/**
 * @class AssetsService
 */
export default class AssetsService {
  /**
   * Return categories
   * @endpoint /common/assets/categories
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Array<Category>>
   */
  static getCategories(): Promise<Array<Category>> {
    return ApiService.get("/common/assets/categories");
  }

  /**
   * Return countries
   * @endpoint /common/assets/countries
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Country[]>
   */
  static getCountries(): Promise<Country[]> {
    return ApiService.get("/common/assets/countries");
  }

  /**
   * Return state
   * @endpoint /assets/state
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Country[]>
   */
  static getStates(countryCode: string): Promise<State[]> {
    return ApiService.get(`/common/assets/states/${countryCode}`);
  }

  /**
   * Return genders
   * @endpoint /common/assets/genders
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Array<string>>
   */
  static getGenders(): Promise<Gender[]> {
    return ApiService.get("/common/assets/genders");
  }

  /**
   * Return certifications
   * @endpoint /common/assets/job-types
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<UgcCertifications>
   */
  static getUgcCertifications(): Promise<UgcCertification[]> {
    return ApiService.get("/common/assets/job-types");
  }

  /**
   * Return levels info
   * @endpoint /common/assets/level-requirements
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<LevelRequirements[]>
   */
  static levelRequirements(): Promise<LevelRequirements[]> {
    return ApiService.get("/common/assets/level-requirements");
  }

  static getMinSalary(data: MinSalaryData): Promise<MinSalaryResponse> {
    return ApiService.post(
      "/common/assets/recurring-contract-min-salary",
      data
    );
  }
}
