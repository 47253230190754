import type { Ref } from "vue";
import { ref, watch } from "vue";

const LocalData = (key: string): Ref => {
  const prefixedKey = `${import.meta.env.VITE_APP_LOCAL_STORAGE_PREFIX}.${key}`;
  const init = localStorage.getItem(prefixedKey);

  const currentValue = ref(init ? JSON.parse(init) : undefined);

  watch(
    () => currentValue.value,
    (to) => {
      localStorage.setItem(prefixedKey, JSON.stringify(to));
      if (to == null) {
        localStorage.removeItem(prefixedKey);
      }
    },
    { deep: true }
  );

  return currentValue;
};

const ClearLocalData = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(import.meta.env.VITE_APP_LOCAL_STORAGE_PREFIX)) {
      localStorage.removeItem(key);
    }
  });
};
export default LocalData;
export { ClearLocalData };
