import { computed, markRaw, watch } from "vue";
import { useLocalStorage } from "@vueuse/core";
import { useDsToastNotifierStore } from "@devsalsa/vue-core";
import { DsNotifier } from "@devsalsa/vue-core";
import AuthUserLoggedOutToast from "@/core/shared/helpers/Auth/AuthUserLoggedOutToast.vue";
import AuthUserChangedToast from "@/core/shared/helpers/Auth/AuthUserChangedToast.vue";

export default class AuthHandler {
  /**
   * Watch the authentication token to check user changes
   */
  static watchToken() {
    const token = computed(() => {
      return useLocalStorage("token", "").value;
    });

    watch(token, (newValue, oldValue) => {
      if (oldValue !== "" && newValue === "") {
        //The user has logged out.
        if (document.visibilityState === "hidden") {
          //Only display the toast if the tab is not the active one.
          if (useDsToastNotifierStore().userLoggedOutNotificationId === 0) {
            //Send notification
            const id = DsNotifier.sendInfo({
              duration: 0,
              enableClose: false,
              position: "top-0 right-0",
              overlay: true,
              component: markRaw({
                name: AuthUserLoggedOutToast,
              }),
            });
            useDsToastNotifierStore().setUserLoggedOutId(id);
          }
        }
      } else if (oldValue !== "" && newValue !== "" && oldValue !== newValue) {
        //The user changed.
        if (useDsToastNotifierStore().userChangedNotificationId === 0) {
          //Send notification
          const id = DsNotifier.sendInfo({
            duration: 0,
            enableClose: false,
            position: "top-0 right-0",
            overlay: true,
            component: markRaw({
              name: AuthUserChangedToast,
            }),
          });
          useDsToastNotifierStore().setUserChangedId(id);
        }
      }
    });
  }
}
