import { ApiService } from "@/core/shared/services/ApiService";
import type {
  ChatMessageFormData,
  ChatMessageReadFormData,
  ChatMessageInfo,
  ResponseMessageReadUpdated,
  ResponseMessageUnreadUpdated,
  ChatResponse,
  Chat,
} from "@/services/ChatService.types";
import type { ChatType } from "@/modules/chat/enums/ChatTypeEnum";

/**
 * @class ChatService
 */
export default class ChatService {
  /**
   * Get chat information
   * @endpoint /chat/{id}/company
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Chat>
   */
  static get(chatId: number): Promise<Chat> {
    return ApiService.get(`/creator/chats/${chatId}`);
  }

  /**
   * Send creator message
   * @endpoint /chat/creator/message
   * @httpMethod POST
   * @throws {ApiServiceError}
   * @return Promise<ChatMessageInfo>
   */
  static sendMessage(data: ChatMessageFormData): Promise<ChatMessageInfo> {
    return ApiService.post("/creator/chats/messages", { ...data });
  }

  /**
   * Get history creator
   * @endpoint /chat/{id}/creator/messages
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<ChatMessageInfo[]>
   */
  static getHistory(
    chatId: number,
    sequenceId = 0
  ): Promise<ChatMessageInfo[]> {
    return ApiService.get(`/creator/chats/${chatId}/messages/${sequenceId}`);
  }

  /**
   * Send creator message
   * @endpoint /creator/chats/messages/read
   * @httpMethod POST
   * @throws {ApiServiceError}
   * @return Promise<ResponseMessageReadUpdated>
   */
  static messageRead(
    data: ChatMessageReadFormData
  ): Promise<ResponseMessageReadUpdated> {
    return ApiService.put("/creator/chats/messages/read", { ...data });
  }

  static markUnread(chatId: number): Promise<ResponseMessageUnreadUpdated> {
    return ApiService.put("/creator/chats/messages/unread", {
      chat_id: chatId,
    });
  }

  /**
   * Search all chats
   * @endpoint /creator/chats/${page}/${rows}
   * @httpMethod POST
   * @param page {number} Page to load
   * @param rows {number} Number of rows to load
   * @param chatType
   * @throws {BadRequestApiServiceError}
   * @return {Promise<ChatResponse>}
   */
  static list(
    page = 0,
    rows = 100,
    chatType: ChatType[]
  ): Promise<ChatResponse> {
    return ApiService.post(`/creator/chats/${page}/${rows}`, {
      chat_types: chatType,
    });
  }
}
