import AuthService from "@/core/shared/services/Auth/AuthService";
import router from "@/router";
import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import {
  MaintenanceApiServiceError,
  UnauthorizedApiServiceError,
  UnauthorizedTwoFactorAuthenticationApiServiceError,
} from "@/core/shared/services/Error/ApiServiceError";
import { DsFlashNotifier } from "@devsalsa/vue-core";
import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import { MaintenanceHandler } from "@/core/shared/helpers/Maintenance/MaintenanceHandler";

export class ErrorHandler {
  static handle(error: unknown): void {
    if (error instanceof UnauthorizedApiServiceError) {
      this.handleUnauthorized(error);
    } else if (
      error instanceof UnauthorizedTwoFactorAuthenticationApiServiceError
    ) {
      this.handleUnauthorizedTwoFactorAuthentication();
    } else if (error instanceof MaintenanceApiServiceError) {
      this.handleMaintenance(error);
    }
  }

  private static async handleUnauthorized(
    error: ApiServiceError
  ): Promise<void> {
    if (AuthService.isAuthenticated()) {
      AuthService.logout();
    }
    if (router.currentRoute.value.name !== "Login") {
      await router
        .push({
          name: "Login",
        })
        .then();
    }
    DsFlashNotifier.error(ErrorTranslator.translate(error));
  }

  private static handleUnauthorizedTwoFactorAuthentication(): void {
    if (router.currentRoute.value.name !== "TwoFactorAuthentication") {
      router.push({
        name: "TwoFactorAuthentication",
      });
    }
  }

  private static handleMaintenance(error: ApiServiceError): void {
    MaintenanceHandler.setOn(error);
  }
}
