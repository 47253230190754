<template>
  <div class="onboarding-navbar">
    <div class="onboarding-navbar-content">
      <div class="onboarding-navbar-content-button-active">
        <a
          v-if="currentStep > 1"
          @click="$router.push({ name: backLocation })"
          class="flex items-center cursor-pointer"
          data-testid="onboarding-back-btn"
        >
          <ArrowLeftSolidIcon class="onboarding-navbar-icon" />
          <p class="onboarding-navbar-icon-label">
            {{ $t("common.button.back") }}
          </p>
        </a>
      </div>
      <div class="onboarding-navbar-title-active" data-testid="onboarding-step">
        {{
          $t("modules.onboarding.onboardingNavbar.text", {
            step: currentStep,
          })
        }}
        {{ currentTitle }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Onboarding } from "@/modules/onboarding/shared/Onboarding";

import ArrowLeftSolidIcon from "@/shared/components/icon/ArrowLeftSolidIcon.vue";

export default defineComponent({
  name: "OnboardingNavbar",
  components: { ArrowLeftSolidIcon },
  computed: {
    currentStep(): number {
      return this.$route.meta.stepperPosition as number;
    },
    currentTitle(): string {
      return this.$t(this.$route.meta.title as string);
    },
    backLocation(): string {
      const position = this.$route.meta.stepperPosition as number;
      const step = Onboarding.getSteps().filter(
        (item) => item.position == position - 1
      );

      return step[0].name;
    },
  },
});
</script>

<style lang="scss" scoped>
.onboarding-navbar {
  @apply bg-gray-50 sm:bg-white py-4 lg:py-10;

  &-content {
    @apply grid grid-cols-12 text-gray-900 text-base lg:text-2xl font-medium lg:font-semibold max-h-8;

    &-button-active {
      @apply flex justify-center items-center inline-block col-span-2 lg:col-span-3 2xl:col-span-4;
    }

    &-button {
      @apply flex justify-center items-center inline-block col-span-3 sm:col-span-2 2xl:col-span-3;
    }
  }

  &-title-active {
    @apply flex items-center col-span-9 sm:col-span-8 lg:col-span-6;
  }

  &-title {
    @apply flex items-center col-span-9 sm:col-span-8 lg:col-span-8 2xl:col-span-6;
  }

  &-icon {
    @apply w-4 h-4 fill-jb-gray-900;
  }

  &-icon-label {
    @apply ml-2 text-base font-medium hidden md:block;
  }
}
</style>
