import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

export default [
  {
    path: "/jobs",
    name: "Jobs",
    component: () => import("@/modules/jobs/views/TheJobs.vue"),
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "common.route.jobs.meta" },
  },
];
