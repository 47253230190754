<template>
  <div class="table-star">
    <StarSolidIcon class="w-5 h-5 mr-2 fill-jb-yellow-500" />
    {{ label }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import StarSolidIcon from "@/shared/components/icon/StarSolidIcon.vue";

export default defineComponent({
  name: "TableStar",
  components: { StarSolidIcon },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.table-star {
  @apply flex items-center;
}
</style>
