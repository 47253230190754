import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Onboarding } from "@/modules/onboarding/shared/Onboarding";

export default [
  {
    path: "/onboarding/",
    beforeEnter: AuthGuard.withIncompleteOnboarding,
    children: [
      {
        path: "personal-information",
        name: "PersonalInformation",
        component: () =>
          import("@/modules/onboarding/views/PersonalInformation.vue"),
        meta: {
          title: "modules.onboarding.personalInformation.meta",
          Navbar: true,
          stepperPosition: Onboarding.PERSONAL_INFORMATION,
          layout: "OnboardingLayout",
        },
      },
      {
        path: "about-me",
        name: "AboutMe",
        component: () => import("@/modules/onboarding/views/AboutMe.vue"),
        meta: {
          title: "modules.onboarding.aboutMe.meta",
          Navbar: true,
          stepperPosition: Onboarding.ABOUT_ME,
          layout: "OnboardingLayout",
        },
      },
      {
        path: "social-networks",
        name: "SocialNetworks",
        component: () =>
          import("@/modules/onboarding/views/SocialNetworks.vue"),
        meta: {
          title: "modules.onboarding.socialNetworks.meta",
          Navbar: true,
          stepperPosition: Onboarding.SOCIAL_NETWORKS,
          layout: "OnboardingLayout",
        },
      },
      {
        path: "categories",
        name: "Categories",
        component: () => import("@/modules/onboarding/views/TheCategories.vue"),
        meta: {
          title: "modules.onboarding.categories.meta",
          Navbar: true,
          stepperPosition: Onboarding.CATEGORIES,
          layout: "OnboardingLayout",
        },
      },
      {
        path: "sample-photo",
        name: "SamplePhoto",
        component: () => import("@/modules/onboarding/views/SamplePhoto.vue"),
        meta: {
          title: "modules.onboarding.samplePhoto.meta",
          Navbar: true,
          stepperPosition: Onboarding.SAMPLE_PHOTO,
          layout: "OnboardingLayout",
        },
      },
      {
        path: "sample-video",
        name: "SampleVideo",
        component: () => import("@/modules/onboarding/views/SampleVideo.vue"),
        meta: {
          title: "modules.onboarding.sampleVideo.meta",
          Navbar: true,
          stepperPosition: Onboarding.SAMPLE_VIDEO,
          layout: "OnboardingLayout",
        },
      },
      {
        path: "disclaimer",
        name: "Disclaimer",
        component: () => import("@/modules/onboarding/views/TheDisclaimer.vue"),
        meta: {
          title: "modules.onboarding.disclaimer.meta",
          Navbar: true,
          stepperPosition: Onboarding.DISCLAIMER,
          layout: "OnboardingLayout",
        },
      },
      {
        path: "summary",
        name: "Summary",
        component: () =>
          import("@/modules/onboarding/views/OnboardingSummary.vue"),
        meta: {
          title: "modules.onboarding.summary.meta",
          Navbar: true,
          stepperPosition: Onboarding.SUMMARY,
          layout: "OnboardingLayout",
        },
      },
      {
        path: "application-sent",
        name: "ApplicationSent",
        component: () =>
          import("@/modules/onboarding/views/ApplicationSent.vue"),
        meta: {
          title: "modules.onboarding.applicationSent.meta",
          stepperPosition: Onboarding.APPLICATION_SENT,
          layout: "OnboardingLayout",
        },
      },
    ],
  },
];
