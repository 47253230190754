import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

export default [
  {
    path: "/jobs/recurring",
    name: "TheRecurringJobs",
    component: () =>
      import("@/modules/recurring-jobs/views/TheRecurringJobs.vue"),
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: {
      title: "common.route.recurringJobs.meta",
      cssClasses: ["authenticated-layout-content-white"],
    },
  },
];
