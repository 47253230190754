import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import type { RouteLocationNormalized } from "vue-router";
import ChatGuard from "@/modules/chat/helpers/ChatGuard";

export default [
  {
    path: "",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    children: [
      //Redirect the old routes to chat.
      {
        path: "/resolution-center",
        redirect: "/chat",
      },
      {
        path: "/resolution-center/:chatId(\\d+)?",
        redirect: (to: RouteLocationNormalized) => {
          return { path: `/chat/${to.params.chatId}` };
        },
      },
      {
        path: "/chat/:chatId(\\d+)?",
        name: "TheChat",
        component: () => import("@/modules/chat/views/TheChat.vue"),
        beforeEnter: ChatGuard.withValidChat,
        meta: {
          title: "common.route.chat.meta",
          cssClasses: ["authenticated-layout-content-white"],
        },
      },
    ],
  },
];
