import * as Sentry from "@sentry/browser";
import {
  BadRequestApiServiceError,
  CanceledApiServiceError,
  MaintenanceApiServiceError,
  UnauthorizedApiServiceError,
  UnauthorizedTwoFactorAuthenticationApiServiceError,
} from "@/core/shared/services/Error/ApiServiceError";
import AuthService from "@/core/shared/services/Auth/AuthService";
import type { ApiErrorResponse } from "@/core/shared/services/ApiService";
import { getUnixTime } from "date-fns";
import { AxiosError, HttpStatusCode } from "axios";

export class ApiErrorHandler {
  static handle(error: AxiosError): void {
    let response: ApiErrorResponse;
    if (error.request && error.request.response) {
      try {
        response = JSON.parse(error.request.response);
      } catch (e) {
        response = {
          code: "invalid_response",
          message: "Invalid response",
          data: {
            response: error.request,
          },
          ts: getUnixTime(new Date()),
        };
      }
    } else if (error.request && error.code === AxiosError.ERR_CANCELED) {
      throw new CanceledApiServiceError(
        "cancellation",
        `Request canceled (${error.code} ${error.message})`,
        []
      );
    } else {
      //Network issue
      response = {
        code: "network_error",
        message: `Network error (${error.code} ${error.message})`,
        data: { code: "network_error" },
        ts: +new Date(),
      };
    }

    Sentry.setContext("xhr", {
      endpoint: error.request.responseURL,
      token: AuthService.getToken() || "",
      response: {
        code: response.code,
        message: response.message,
        data: JSON.stringify(response.data),
      },
    });

    //Unauthenticated user.
    if (
      [HttpStatusCode.Unauthorized, HttpStatusCode.RequestTimeout].includes(
        error.request?.status
      )
    ) {
      throw new UnauthorizedApiServiceError(
        response.code,
        response.message,
        response.data
      );
    }

    //2FA unauthenticated user.
    if ([HttpStatusCode.PreconditionRequired].includes(error.request?.status)) {
      throw new UnauthorizedTwoFactorAuthenticationApiServiceError(
        response.code,
        response.message,
        response.data
      );
    }

    //API maintenance.
    if ([HttpStatusCode.ServiceUnavailable].includes(error.request?.status)) {
      throw new MaintenanceApiServiceError(
        response.code,
        response.message,
        response.data
      );
    }

    //A bad request could be a 400, 404, 429 or 500 error.
    throw new BadRequestApiServiceError(
      response.code,
      response.message,
      response.data
    );
  }
}
