<template>
  <DsSidebar v-model="isShowing" width-css-class="mobile-menu-sidebar">
    <template #body>
      <div class="mobile-menu-sidebar-body">
        <div class="mobile-menu-sidebar-header">
          <div class="mobile-menu-sidebar-avatar">
            <DsAvatar :alt="fullName" :src="profileImage" size="md" />
            <div class="mobile-menu-sidebar-header-close">
              <button class="cursor-pointer" @click="isShowing = !isShowing">
                <CloseSolidIcon class="mobile-menu-sidebar-header-icon" />
              </button>
            </div>
          </div>
          <p class="mobile-menu-sidebar-name">
            {{ fullName }}
          </p>
          <div class="mobile-menu-sidebar-email">
            {{ email }}
          </div>
        </div>
        <div class="mobile-menu-sidebar-container">
          <ul>
            <li
              v-for="(item, key) in items"
              :key="key"
              class="mobile-menu-sidebar-options"
            >
              <DsLink
                @click="onClose"
                :data-testid="item.dataTestId"
                :to="item.route"
                :class="[
                  isActive(item) ? 'link-offcanvas-active' : 'link-offcanvas',
                  item.cssClasses,
                ]"
              >
                {{ item.label }}
              </DsLink>
            </li>
          </ul>
          <ul class="mobile-menu-sidebar-divider">
            <li
              v-for="(item, key) in itemsAccount"
              :key="key"
              class="mobile-menu-sidebar-options"
            >
              <DsLink
                v-if="Object.keys(item.route).length > 0"
                @click="onClose"
                :data-testid="item.dataTestId"
                :to="item.route"
                :class="[
                  isActive(item) ? 'link-offcanvas-active' : 'link-offcanvas',
                  item.cssClasses,
                ]"
              >
                {{ item.label }}
              </DsLink>
              <DsButton
                v-else
                theme-display="link"
                @click.prevent="callFunction(item)"
                :data-testid="item.dataTestId"
                class="link-offcanvas text-left"
              >
                {{ item.label }}
              </DsButton>
            </li>
          </ul>
        </div>
        <div class="mobile-menu-sidebar-footer">
          <DsLink :to="{ name: 'Logout' }" class="block link-offcanvas">
            {{ $t("common.route.logout.link") }}
          </DsLink>
        </div>
      </div>
    </template>
  </DsSidebar>
</template>

<script lang="ts">
/**
 * Offcanvas component
 * @version 2.0.0 - 2022-06-1
 */
import { computed, defineComponent } from "vue";
import type { PropType } from "vue";
import type { LocationAsRelativeRaw } from "vue-router";

import { DsAvatar, DsButton, DsLink, DsSidebar } from "@devsalsa/vue-core";

import CloseSolidIcon from "@/shared/components/icon/CloseOutlineIcon.vue";

import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default defineComponent({
  name: "OffCanvas",
  components: {
    DsAvatar,
    DsLink,
    CloseSolidIcon,
    DsSidebar,
    DsButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    items: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
    itemsAccount: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
  },
  data() {
    return {
      accountInfo: computed(
        () =>
          AccountHandler.accountInfo.value ?? {
            first_name: "",
            last_name: "",
            profile_image: "",
            email: "",
          }
      ),
    };
  },
  watch: {
    grid(val) {
      this.isShowing = this.isShowing && val;
    },
  },
  computed: {
    grid() {
      return this.$grid("xl:hidden");
    },
    isShowing: {
      get() {
        return this.isOpen;
      },
      set(val: boolean) {
        this.$emit("update:isOpen", val);
      },
    },
    email(): string {
      return `${this.accountInfo.email || ""}`;
    },
    fullName(): string {
      return `${this.accountInfo.first_name || ""} ${
        this.accountInfo.last_name || ""
      }`;
    },
    profileImage(): string {
      return `${this.accountInfo.profile_image || ""}`;
    },
  },
  methods: {
    onClose(): void {
      this.isShowing = false;
    },
    isActive(item: NavigationItem): boolean {
      return this.$route.name === (item.route as LocationAsRelativeRaw).name;
    },
    callFunction(item: NavigationItem): void {
      if (item.action && typeof item.action === "function") {
        this.onClose();
        item.action();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.link-offcanvas {
  @apply text-gray-500 text-sm font-medium p-2 no-underline hover:no-underline
  hover:text-gray-900 hover:bg-gray-100 hover:rounded-lg;
  &-active {
    @apply text-sm font-medium py-2 px-2 text-gray-900 bg-gray-100 rounded-lg no-underline hover:no-underline;
  }
}

.mobile-menu-sidebar {
  &-header {
    @apply px-6 border-b border-gray-200 pb-4 z-10 bg-white sticky top-0 pt-6;

    &-close {
      @apply ml-3 flex h-7 items-center;
    }

    &-icon {
      @apply w-8 h-8 fill-gray-900;
    }
  }

  &-avatar {
    @apply flex items-start justify-between;
  }

  &-name {
    @apply text-lg font-medium text-gray-900 pt-2;
  }

  &-email {
    @apply text-gray-500 text-sm font-normal;
  }

  &-container {
    @apply relative py-6 flex-1 overflow-y-auto;
  }

  &-body {
    @apply flex h-full flex-col relative;
  }

  &-footer {
    @apply border-t border-gray-200 p-4 w-full bg-white sticky bottom-0;
  }

  &-options {
    @apply space-y-2 flex flex-col px-4;
  }

  &-divider {
    @apply border-t border-gray-200 mt-4 pt-4;
  }
}
</style>
