<template>
  <!-- No root element so the DsToast container style can be applied -->
  <UserSolidIcon class="auth-user-changed-toast-icon" />
  <div class="auth-user-changed-toast-content">
    <h6 class="auth-user-changed-toast-title">
      {{ title }}
    </h6>
    <p class="auth-user-changed-toast-message">
      {{ message }}
    </p>
    <div>
      <DsButton @click="onClick" class="mt-2">
        {{ $t("common.button.reload") }}
      </DsButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DsButton } from "@devsalsa/vue-core";

import UserSolidIcon from "@/shared/components/icon/UserSolidIcon.vue";

export default defineComponent({
  name: "AuthUserChangedToast",
  components: {
    DsButton,
    UserSolidIcon,
  },
  methods: {
    onClick() {
      window.location.reload();
    },
  },
  computed: {
    title(): string {
      return this.$t("common.error.global.userChanged.title");
    },
    message(): string {
      return this.$t("common.error.global.userChanged.message");
    },
  },
});
</script>

<style lang="scss" scoped>
.auth-user-changed-toast {
  &-icon {
    @apply flex-none w-5 h-5;
  }

  &-content {
    @apply col-span-2 space-y-1 flex flex-col items-start w-[300px] md:w-[320px] break-words line-clamp-6;
  }

  &-title {
    @apply text-sm font-medium text-gray-900 w-full;
  }

  &-message {
    @apply text-sm font-normal text-gray-500 w-full;
  }
}
</style>
