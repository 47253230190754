<template>
  <transition name="fade" mode="out-in">
    <component :is="layout" v-if="layout" />
  </transition>
  <NavigationLoader />
  <DsToastNotifier />
  <AppNotifier v-if="showAppNotifierOnlyOnDesktop" />
  <VersionManagerNotifier />
</template>

<script lang="ts">
import { defineComponent, markRaw } from "vue";
import PageNotFoundLayout from "@/shared/modules/page-not-found/components/PageNotFoundLayout.vue";
import type { Presence } from "@/store/ChatStore";
import { useChatStore } from "@/store/ChatStore";
import { useWebSocketStore } from "@/store/WebSocketStore";
import { useDebounceFn } from "@vueuse/core";

import {
  DsNotifier,
  DsToastNotifier,
  useDsToastNotifierStore,
} from "@devsalsa/vue-core";

import AccountLayout from "@/core/modules/account/components/AccountLayout.vue";
import AuthenticatedLayout from "@/core/shared/components/Layout/AuthenticatedLayout.vue";
import NavigationLoader from "@/core/shared/components/Navigation/NavigationLoader.vue";
import AppNotifier from "@/core/shared/components/Toast/AppNotifier.vue";
import VersionManagerNotifier from "@/core/shared/components/Toast/VersionManagerNotifier.vue";
import WebSocketReconnectionErrorToast from "@/core/shared/plugins/WebSocket/WebSocketReconnectionErrorToast.vue";

import OnboardingLayout from "@/modules/onboarding/components/OnboardingLayout.vue";
import SettingsLayout from "@/modules/settings/components/SettingsLayout.vue";

import AccountService from "@/core/modules/account/services/AccountService";
import { WebSocketService } from "@/core/shared/services/WebSocket/WebSocketService";
import PresenceService from "@/services/PresenceService";

import AuthHandler from "@/core/shared/helpers/Auth/AuthHandler";
import Browser from "@/core/shared/helpers/Browser";

export default defineComponent({
  components: {
    DsToastNotifier,
    AppNotifier,
    VersionManagerNotifier,
    NavigationLoader,
    AccountLayout,
    AuthenticatedLayout,
    SettingsLayout,
    PageNotFoundLayout,
    OnboardingLayout,
  },
  data() {
    return {
      layout: "",
      intervalId: 0,
      unsubscribePresences: () => {
        return;
      },
      debouncePresences: () => {
        return;
      },
      unsubscribeSocketAuthenticated: () => {
        return;
      },
    };
  },
  created() {
    AuthHandler.watchToken();
    this.debouncePresences = useDebounceFn(() => {
      this.pollPresence();
    }, 500);

    this.unsubscribePresences = useChatStore().$onAction(({ name, after }) => {
      after(() => {
        if (name === "addRegisterPresence") {
          this.debouncePresences();
        }
      });
    });

    this.unsubscribeSocketAuthenticated = useWebSocketStore().$onAction(
      ({ name, after }) => {
        after(() => {
          if (
            name === "setAuthenticated" &&
            WebSocketService.isAuthenticated() &&
            WebSocketService.isConnected()
          ) {
            AccountService.webSocketInitialized();
          } else if (name === "onReconnectError") {
            if (useDsToastNotifierStore().websocketNotificationId === 0) {
              //Send notification
              const id = DsNotifier.sendInfo({
                duration: 0,
                enableClose: false,
                position: "top-0 right-0",
                overlay: true,
                component: markRaw({
                  name: WebSocketReconnectionErrorToast,
                }),
              });
              useDsToastNotifierStore().setWebsocketNotificationId(id);
            }
          }
        });
      }
    );

    this.enableInterval();
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.enableInterval();
      } else {
        window.clearInterval(this.intervalId);
      }
    });
  },
  unmounted() {
    this.unsubscribePresences();
    this.unsubscribeSocketAuthenticated();
  },
  computed: {
    showAppNotifierOnlyOnDesktop(): boolean {
      return !Browser.isMobile();
    },
  },
  methods: {
    async pollPresence() {
      const presenceIds = useChatStore().presences.map(
        (presence) => presence.id
      );
      if (presenceIds.length > 0) {
        const responsePresences = await PresenceService.poll(presenceIds);
        const newPresences = responsePresences.map((company): Presence => {
          return {
            id: company.company_id,
            presence: company.presence ? "active" : "inactive",
          };
        });
        useChatStore().setPresences(newPresences);
      }
    },
    enableInterval() {
      this.intervalId = window.setInterval(() => {
        this.pollPresence();
      }, 60000);
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.meta.layout !== undefined) {
          this.layout = to.meta.layout;
        } else {
          this.layout = "AuthenticatedLayout";
        }
      },
    },
  },
});
</script>
