import * as Sentry from "@sentry/vue";
import { ClearLocalData } from "@/core/shared/helpers/LocalData";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default class AuthService {
  /**
   * Save the token in the local storage.
   *
   * @param token
   */
  static setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  /**
   * Get the curren token stored in the local storage.
   *
   * @returns string
   */
  static getToken(): string {
    return localStorage.getItem("token") ?? "";
  }

  /**
   * @returns boolean
   */
  static isAuthenticated(): boolean {
    return this.getToken() !== "";
  }

  /**
   * Remove the token from the local storage
   */
  static logout(): void {
    //Clear the user in the memory.
    AccountHandler.logout();
    //Clear all keys from local storage.
    ClearLocalData();
    //Clear the token.
    localStorage.removeItem("token");
    //Clear the user from Sentry.
    if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }
}
