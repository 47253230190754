<template>
  <header class="authenticated-navbar" data-testid="authenticated-navbar">
    <div class="authenticated-navbar-panel">
      <!--	  logo svg -->
      <DsLink class="authenticated-navbar-logo" :to="{ name: 'Dashboard' }">
        <JoinBrandsLogoIcon class="w-[33px] h-11" />
      </DsLink>
      <!--	 Links navbar lg to 2xl -->
      <div
        v-if="$grid('hidden xl:block')"
        class="authenticated-navbar-panel-access"
      >
        <NavbarItem
          :to="{ name: 'Dashboard' }"
          route-name="Dashboard"
          :text="$t('common.route.dashboard.link')"
          class="mr-8"
        />
        <NavbarItem
          :to="{ name: 'Marketplace' }"
          route-name="Marketplace"
          :text="$t('common.route.marketplace.link')"
          class="mr-8"
        />
        <NavbarItem
          :to="{ name: 'Jobs' }"
          route-name="Jobs"
          :text="$t('common.route.jobs.link')"
          class="mr-8"
        />
        <NavbarItem
          :to="{ name: 'TheRecurringJobs' }"
          route-name="TheRecurringJobs"
          :text="$t('common.route.recurringJobs.link')"
          class="mr-8"
        />
      </div>
      <!--	  Icons, Avatar and button sidebar-->
      <div class="ml-auto flex items-center">
        <template v-if="isImpersonated">
          <DsBadge
            :title="$t('common.label.admin', { id: adminId })"
            size="xs"
            class="mr-3"
            data-testid="admin-impersonated-badge"
          />
          <DsBadge
            v-if="isSuspended"
            :title="$t('common.label.suspended')"
            theme-color="red"
            size="xs"
            class="mr-3"
            data-testid="user-suspended-badge"
          />
        </template>
        <NavbarItem
          :to="{ name: 'Wallet' }"
          route-name="Wallet"
          :text="$currency.format(wallet)"
          class="mr-6 hidden sm:block"
        />
        <NavbarItem
          route-name="TheChat"
          :to="{
            name: 'TheChat',
            params: { chatId: $route.params.chatId },
          }"
          icon="MessagesSolidIcon"
          class="chat-icon"
          data-testid="chat-icon"
        >
          <template #navbar-item>
            <PointIcon
              v-if="hasNewChats"
              class="chat-dot-icon"
              data-testid="new-chats-dot-icon"
            />
          </template>
        </NavbarItem>
        <NavbarItem
          route-name="Notifications"
          @click="onClickShowNotifications"
          icon="AppNotificationSolidIcon"
          class="notification-icon"
          :link="false"
          data-testid="app-notifications-icon"
        >
          <template #navbar-item>
            <PointIcon
              class="notification-dot-icon"
              v-if="hasNotifications"
              data-testid="app-notifications-dot-icon"
            />
          </template>
        </NavbarItem>
        <div v-if="$grid('xl:hidden')" class="flex items-center">
          <button @click="showMenu = !showMenu">
            <MenuSolidIcon class="w-6 h-6 fill-jb-gray-900" />
          </button>
        </div>
        <div v-if="$grid('hidden xl:block')">
          <DsDropdown :items="avatarLinks" close-on-mouse-leave>
            <template #button>
              <DsAvatar
                :alt="fullName"
                :src="profileImage"
                size="sm"
                data-testid="avatar-dropdown-menu"
              />
            </template>
          </DsDropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useAppNotifierStore } from "@/store/AppNotifierStore";
import { useChatStore } from "@/store/ChatStore";

import type { DsDropdownItem } from "@devsalsa/vue-core";
import {
  DsAvatar,
  DsBadge,
  DsDropdown,
  DsLink,
  DsModalHandler,
} from "@devsalsa/vue-core";

import NavbarItem from "@/core/shared/components/Navigation/NavbarItem.vue";

import JoinBrandsLogoIcon from "@/shared/components/icon/JoinBrandsLogoIcon.vue";
import MenuSolidIcon from "@/shared/components/icon/MenuSolidIcon.vue";
import PointIcon from "@/shared/components/icon/PointIcon.vue";

import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";

import { MarketplaceList } from "@/core/modules/account/enums/MarketplaceEnum";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default defineComponent({
  name: "AuthenticatedNavbar",
  components: {
    MenuSolidIcon,
    PointIcon,
    DsDropdown,
    NavbarItem,
    DsAvatar,
    DsLink,
    JoinBrandsLogoIcon,
    DsBadge,
  },
  props: {
    offCanvasValue: {
      required: true,
      type: Boolean,
    },
    notificationValue: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      marketplace: "us",
      avatarLinks: [
        {
          label: this.$t("core.shared.components.layout.navbar.account"),
          route: {
            name: "Account",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.portfolio"),
          route: {
            name: "PortfolioSummary",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.reviews"),
          route: {
            name: "Reviews",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.wallet"),
          route: {
            name: "Wallet",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.payoutMethod"),
          route: {
            name: "PayoutMethod",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.certifications"),
          route: {
            name: "Certifications",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.notifications"),
          route: {
            name: "NotificationSettings",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.requestFeature"),
          route: {
            name: "RequestAFeature",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.contact"),
          action: () => this.onOpenModal(),
          dataTestid: "contact-support-avatar-menu-link",
        },
        {
          label: this.$t("core.shared.components.layout.navbar.affiliate"),
          action: () => this.onGoToAffiliates(),
        },
        {
          label: this.$t("common.route.logout.link"),
          route: {
            name: "Logout",
          },
          cssClasses: "border-t border-gray-100",
        },
      ] as DsDropdownItem[],
    };
  },
  computed: {
    showMenu: {
      get(): boolean {
        return this.offCanvasValue;
      },
      set(val: boolean) {
        this.$emit("update:offCanvasValue", val);
      },
    },
    showNotifications: {
      get(): boolean {
        return this.notificationValue;
      },
      set(notificationVal: boolean) {
        this.$emit("update:notificationValue", notificationVal);
      },
    },
    accountInfo(): AccountInfo {
      return AccountHandler.accountInfo.value as AccountInfo;
    },
    fullName(): string {
      return `${this.accountInfo?.first_name ?? ""} ${
        this.accountInfo?.last_name ?? ""
      }`;
    },
    profileImage(): string {
      return `${this.accountInfo?.profile_image || ""}`;
    },
    wallet(): number {
      return (
        Number(this.accountInfo?.wallet_earnings_balance) +
        Number(this.accountInfo?.wallet_reimbursements_balance)
      );
    },
    hasNotifications(): boolean {
      return useAppNotifierStore().unreadNotifications > 0;
    },
    hasNewChats(): boolean {
      return useChatStore().unreadChats > 0;
    },
    isSuspended(): boolean {
      return this.accountInfo.suspended;
    },
    adminId(): number {
      return this.accountInfo?.admin_id ?? 0;
    },
    isImpersonated(): boolean {
      return this.adminId > 0;
    },
  },
  methods: {
    onClickShowNotifications() {
      this.showNotifications = true;
    },
    onOpenModal() {
      DsModalHandler.open("contact-support-modal");
    },
    onGoToAffiliates() {
      //TODO pending to define
      if (this.marketplace === MarketplaceList.USMarketplace) {
        this.$router.push({
          name: "AffiliateProgram",
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.authenticated-navbar {
  @apply w-full h-fit bg-white border-b border-gray-200;

  &-panel {
    @apply xl:container px-3 md:px-4 xl:px-6 flex xl:mx-auto;

    &-access {
      @apply flex items-center justify-center mx-8;
    }
  }

  &-logo {
    @apply flex items-center flex-none w-[33px] h-16;
  }
}

.notification-icon {
  @apply relative w-8 h-8 sm:mr-4 mr-1 flex justify-center items-center;
}

.chat-icon {
  @apply relative w-8 h-8 sm:mr-3 flex justify-center pt-[7px];
}

.notification-dot-icon {
  @apply w-3 h-3 absolute right-0 top-0 fill-jb-pink-500 border-2 border-white rounded-full;
}

.chat-dot-icon {
  @apply w-3 h-3 absolute right-0 top-0 fill-jb-pink-500 border-2 border-white rounded-full;
}
</style>
