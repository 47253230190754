<template>
  <footer
    class="authenticated-footer"
    data-testid="authenticated-footer-background"
  >
    <div
      class="authenticated-footer-grid grid-rows-12"
      data-testid="authenticated-footer-grid"
    >
      <div
        class="authenticated-footer-links"
        data-testid="authenticated-footer-blog-academy"
      >
        <!--External link for footer-->
        <ExternalFooterLink
          href="https://joinbrands.com/blog/"
          target="_blank"
          rel="nofollow"
          class="footer-link-blog"
        >
          {{ $t("core.shared.components.layout.footer.blog") }}
        </ExternalFooterLink>
        <ExternalFooterLink
          href="https://help.joinbrands.com/en/"
          target="_blank"
          rel="nofollow"
          class="footer-link-academy"
        >
          {{ $t("core.shared.components.layout.footer.academy") }}
        </ExternalFooterLink>
        <DsButton
          theme-display="link"
          class="footer-link-contact ds-link-no-underline"
          data-testid="contact-support-footer-link"
          @click="onOpenModal"
        >
          {{ $t("core.shared.components.layout.footer.contact") }}
        </DsButton>
        <DsButton
          v-if="false"
          theme-display="link"
          class="footer-link-news ds-link-no-underline space-x-1"
          data-testid="news-footer-link"
        >
          <span>{{ $t("core.shared.components.layout.footer.news") }}</span>
        </DsButton>
      </div>
      <div
        class="authenticated-footer-jb"
        data-testid="authenticated-footer-date"
      >
        <div class="jb-version">© {{ date }} JoinBrands.com {{ version }}</div>
      </div>
      <div
        class="authenticated-footer-terms"
        data-testid="authenticated-footer-terms"
      >
        <div class="policy-and-terms">
          <ExternalFooterLink
            href="https://joinbrands.com/privacy"
            target="_blank"
            class="policy-link"
            rel="nofollow"
          >
            {{ $t("core.shared.components.layout.footer.privacy") }}
          </ExternalFooterLink>
          <ExternalFooterLink
            href="https://joinbrands.com/terms"
            target="_blank"
            class="terms-link"
            rel="nofollow"
          >
            {{ $t("core.shared.components.layout.footer.terms") }}
          </ExternalFooterLink>
        </div>
      </div>
      <div
        class="authenticated-footer-social"
        data-testid="authenticated-footer-social-media"
      >
        <div class="social-links">
          <div class="social-links-list">
            <ExternalFooterLink
              v-for="(item, index) in socialLinks"
              :key="index"
              :href="item.link"
              rel="nofollow"
              target="_blank"
              class="inline-block"
            >
              <img :src="item.icon" :alt="item.name" />
            </ExternalFooterLink>
          </div>

          <div class="language-and-currency">
            <p class="language">
              {{ $t("core.shared.components.layout.footer.english") }}
            </p>
            <p class="currency">
              {{ $t("core.shared.components.layout.footer.currency") }}
            </p>
          </div>
        </div>
      </div>
      <ContactSupportFormModal />
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import facebook from "@/assets/img/social-media/facebook-gray.svg";
import instagram from "@/assets/img/social-media/instagram-gray.svg";
import tiktok from "@/assets/img/social-media/tiktok-gray.svg";
import youtube from "@/assets/img/social-media/youtube-gray.svg";

import { DsButton, DsModalHandler } from "@devsalsa/vue-core";

import ExternalFooterLink from "@/core/shared/components/Link/ExternalFooterLink.vue";

import ContactSupportFormModal from "@/modules/contact/components/ContactSupportFormModal.vue";

export default defineComponent({
  name: "AuthenticatedFooter",
  components: {
    ExternalFooterLink,
    DsButton,
    ContactSupportFormModal,
  },
  data() {
    return {
      date: new Date().getFullYear(),
      version: `v${import.meta.env.PACKAGE_VERSION}`,
      socialLinks: [
        {
          name: "Instagram",
          icon: instagram,
          link: "https://www.instagram.com/joinbrandsnow/",
        },
        {
          name: "Facebook",
          icon: facebook,
          link: "https://www.facebook.com/joinbrands",
        },
        {
          name: "TikTok",
          icon: tiktok,
          link: "https://www.tiktok.com/@joinbrands",
        },
        {
          name: "YouTube",
          icon: youtube,
          link: "https://www.youtube.com/@joinbrands",
        },
      ],
    };
  },
  methods: {
    onOpenModal() {
      DsModalHandler.open("contact-support-modal");
    },
  },
});
</script>

<style lang="scss" scoped>
.authenticated-footer {
  @apply h-auto w-full bg-gray-50 border-t border-gray-200;

  .authenticated-footer-grid {
    @apply grid grid-cols-1 grid-flow-row-dense md:grid-cols-5 md:grid-rows-2 xl:container xl:mx-auto;
  }

  .authenticated-footer-links {
    @apply row-span-4 order-1 border-gray-200 md:row-span-1 md:col-span-3 md:order-1 border-b md:ml-4 xl:ml-6 pl-4 md:pl-0 py-6;

    .footer-link-blog {
      @apply block md:inline-block text-gray-500 text-sm font-medium;
    }

    .footer-link-academy {
      @apply block md:inline-block mt-2 md:mt-0 md:ml-6 text-gray-500 text-sm font-medium;
    }

    .footer-link-contact {
      @apply block md:inline-block mt-2 md:mt-0 md:ml-6 text-gray-500 text-sm font-medium;
    }

    .footer-link-news {
      @apply block md:inline-block mt-2 md:mt-0 md:ml-6 text-gray-500 text-sm font-medium;
    }
  }

  .authenticated-footer-jb {
    @apply row-span-1 md:col-span-3 order-4 md:order-3;

    .jb-version {
      @apply ml-4 xl:ml-6 mb-6 md:mb-0 md:my-6 h-6 flex items-center text-gray-400 text-sm font-normal;
    }
  }

  .authenticated-footer-terms {
    @apply row-span-2 order-2 border-b border-gray-200 md:row-span-1 md:col-span-2 md:order-2 md:mr-4 xl:mr-6;

    .policy-and-terms {
      @apply md:flex justify-end ml-4 md:ml-0 my-6;

      .policy-link {
        @apply block md:inline-block mr-6 md:py-0.5 pb-2 md:pb-0 text-gray-500 text-sm font-medium;
      }

      .terms-link {
        @apply md:py-0.5 text-gray-500 text-sm font-medium;
      }
    }
  }

  .authenticated-footer-social {
    @apply row-span-2 order-3 md:row-span-1 md:col-span-2;

    .social-links {
      @apply md:flex justify-end my-6 mr-4 xl:mr-6;

      img {
        @apply h-5 w-5;
      }

      &-list {
        @apply flex flex-wrap gap-4 mr-2 px-4 md:px-0;
      }

      .language-and-currency {
        @apply flex items-center ml-4 md:ml-6 mt-4 md:mt-0;

        .language {
          @apply text-gray-400 text-sm font-normal mr-4;
        }

        .currency {
          @apply text-gray-400 text-sm font-normal;
        }
      }
    }
  }
}
</style>
