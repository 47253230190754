<template>
  <header class="unauthenticated-navbar">
    <div class="unauthenticated-navbar-container">
      <DsExternalLink
        href="https://joinbrands.com/"
        target="_self"
        theme-color="gray"
        class="unauthenticated-navbar-link"
      >
        <JoinBrandsLogoIcon class="unauthenticated-navbar-icon" />
      </DsExternalLink>

      <div class="flex gap-2 items-center">
        <template v-if="isImpersonated">
          <DsBadge
            :title="$t('common.label.admin', { id: adminId })"
            size="xs"
            class="mr-3"
            data-testid="admin-impersonated-badge"
          />
          <DsBadge
            v-if="isSuspended"
            :title="$t('common.label.suspended')"
            theme-color="red"
            size="xs"
            class="mr-3"
            data-testid="user-suspended-badge"
          />
        </template>
        <DsLink
          v-if="isStepperPage"
          :to="{ name: 'Logout' }"
          outline
          theme-display="button"
          size="lg"
        >
          {{ $t("common.route.logout.link") }}
        </DsLink>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DsBadge, DsExternalLink, DsLink } from "@devsalsa/vue-core";

import JoinBrandsLogoIcon from "@/shared/components/icon/JoinBrandsLogoIcon.vue";

import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default defineComponent({
  name: "UnauthenticatedNavbar",
  components: {
    DsLink,
    DsExternalLink,
    JoinBrandsLogoIcon,
    DsBadge,
  },
  computed: {
    accountInfo() {
      return AccountHandler.accountInfo.value as AccountInfo;
    },
    isStepperPage(): boolean {
      return !!this.$route.meta.stepperPosition;
    },
    isSuspended(): boolean {
      return this.accountInfo.suspended;
    },
    adminId(): number {
      return this.accountInfo && this.accountInfo.admin_id
        ? this.accountInfo.admin_id
        : 0;
    },
    isImpersonated(): boolean {
      return this.adminId > 0;
    },
  },
});
</script>

<style lang="scss" scoped>
.unauthenticated-navbar {
  @apply flex items-center flex-shrink-0 fixed z-10 w-full top-0 bg-white border-b h-16;

  &-container {
    @apply flex items-center justify-between px-4 lg:px-8 w-full;
  }

  &-link {
    @apply no-underline flex items-center block;
  }

  &-icon {
    @apply w-[33px] h-11;
  }
}
</style>
