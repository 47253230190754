<template>
  <div class="notification-description">
    <p
      :class="[
        'notification-description-content',
        {
          'notification-expand': !isSidebar,
        },
        {
          'notification-sidebar': isSidebar,
        },
        {
          'notification-open': !descriptionCropped,
        },
      ]"
    >
      <template v-if="descriptionMore && descriptionCropped">
        <span v-html="notification.message.slice(0, descriptionLimit)"></span>
      </template>
      <template v-else>
        <span v-html="notification.message"></span>
      </template>
    </p>
    <button
      v-if="descriptionMore"
      @click.stop="descriptionCropped = !descriptionCropped"
      class="flex items-center font-medium text-sm text-gray-900 mt-2"
    >
      <span v-if="descriptionCropped">
        {{ $t("common.button.expand") }}
      </span>
      <span v-else> {{ $t("common.button.collapse") }} </span>
      <ChevronDownSolidIcon
        class="w-5 h-5 ml-1 transition duration-300 align-middle"
        :class="{ '-rotate-180': !descriptionCropped }"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import ChevronDownSolidIcon from "@/shared/components/icon/ChevronDownSolidIcon.vue";

import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

export default defineComponent({
  name: "NotificationDescription",
  components: { ChevronDownSolidIcon },
  props: {
    notification: {
      type: Object as PropType<AppNotification>,
      required: true,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descriptionLimit: 75,
      descriptionCropped: true,
    };
  },
  created() {
    if (window.innerWidth < 640) {
      this.descriptionLimit = 115;
    }
  },
  computed: {
    descriptionMore(): boolean {
      return (
        this.isSidebar &&
        Number(this.notification.message.length) > this.descriptionLimit
      );
    },
  },
});
</script>

<style scoped lang="scss">
.notification-description {
  &-content {
    @apply overflow-hidden text-gray-500 text-sm font-normal;

    :deep(a) {
      @apply text-blue-600 hover:underline block mt-2 text-sm;
    }
  }

  .notification-expand {
    @apply max-h-full;
  }

  .notification-sidebar {
    @apply max-h-16 sm:max-h-10;
  }

  & .notification-open {
    @apply max-h-screen transition-all duration-500;
  }
}
</style>
