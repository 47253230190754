<template>
  <div class="notification-menu" @click.stop>
    <DsDropdown :items="dropdownItems" size="sm">
      <template #button>
        <DotsVerticalSolidIcon class="notification-menu-icon" />
      </template>
    </DsDropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import type { DsDropdownItem } from "@devsalsa/vue-core";
import { DsDropdown } from "@devsalsa/vue-core";

import DotsVerticalSolidIcon from "@/shared/components/icon/DotsVerticalSolidIcon.vue";

import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

export default defineComponent({
  name: "NotificationMenu",
  components: { DsDropdown, DotsVerticalSolidIcon },
  emits: ["markRead", "delete"],
  props: {
    notification: {
      type: Object as PropType<AppNotification>,
      required: true,
    },
  },
  data() {
    return {
      dropdownItems: [] as DsDropdownItem[],
    };
  },
  created() {
    this.setDropdownItems();
  },
  methods: {
    setDropdownItems() {
      this.dropdownItems = [
        {
          label: this.$t("common.button.markRead"),
          action: () => this.$emit("markRead", this.notification.id),
          cssClasses:
            Number(this.notification.date_read) > 0
              ? "hidden"
              : "pt-4 text-gray-900",
        },
        {
          label: this.$t("common.button.removeFromList"),
          action: () => this.$emit("delete", this.notification.id),
          cssClasses:
            Number(this.notification.date_read) > 0
              ? "last:py-3 text-gray-900"
              : "pb-4 text-gray-900",
        },
      ];
    },
  },
});
</script>

<style scoped lang="scss">
.notification-menu {
  @apply absolute top-4 right-2 sm:top-6 sm:right-4;

  &-icon {
    @apply h-5 w-5 fill-gray-500;
  }
}
</style>
