<template>
  <router-link
    :to="to"
    class="sidebar-settings-item group"
    :class="[isSelected ? 'text-jb-gray-900' : customClass]"
  >
    <component
      :is="icon"
      class="settings-icon group-hover:text-jb-gray-900"
      :class="[isSelected ? 'text-jb-gray-900' : iconClass]"
    />
    <span
      v-if="!hasSlotTitle"
      class="settings-label group-hover:text-jb-gray-900"
    >
      {{ title }}
    </span>
    <slot v-else :name="idSlot" />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BadgeSolidIcon from "@/shared/components/icon/BadgeSolidIcon.vue";
import BellSolidIcon from "@/shared/components/icon/BellSolidIcon.vue";
import CardSolidIcon from "@/shared/components/icon/CardSolidIcon.vue";
import MoneySolidIcon from "@/shared/components/icon/MoneySolidIcon.vue";
import PortfolioSolidIcon from "@/shared/components/icon/PortfolioSolidIcon.vue";
import ReferralSolidIcon from "@/shared/components/icon/ReferralSolidIcon.vue";
import StarSolidIcon from "@/shared/components/icon/StarSolidIcon.vue";
import UserSolidIcon from "@/shared/components/icon/UserSolidIcon.vue";

export default defineComponent({
  name: "SidebarSettingsItem",
  components: {
    UserSolidIcon,
    PortfolioSolidIcon,
    StarSolidIcon,
    MoneySolidIcon,
    CardSolidIcon,
    BadgeSolidIcon,
    BellSolidIcon,
    ReferralSolidIcon,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: "text-gray-400",
    },
    customClass: {
      type: String,
      default: "text-gray-500",
    },
  },
  data() {
    return {
      idSlot: "title",
    };
  },
  computed: {
    isSelected(): boolean {
      return this.$route.name === this.routeName;
    },
    hasSlotTitle(): boolean {
      return Reflect.has(this.$slots, this.idSlot);
    },
  },
});
</script>

<style lang="scss" scoped>
.sidebar-settings-item {
  @apply flex flex items-center text-sm font-medium rounded-md transition-colors duration-100;

  .settings-icon {
    @apply h-5 w-5 block mr-4 shrink-0;
  }

  .settings-label {
    @apply flex-1;
  }
}
</style>
