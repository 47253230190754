<template>
  <div v-if="needRefresh" class="version-manager-notifier">
    <div class="flex flex-row items-center justify-between text-gray-500">
      <p class="text-sm font-medium text-gray-900">
        {{ $t("common.system.version") }}
      </p>
    </div>
    <p class="text-justify text-sm font-normal text-gray-500 mt-1 w-[200px]">
      {{ $t("common.system.updateMessage") }}
    </p>
    <DsButton
      @click="onClickUpdate"
      :loading="loading"
      data-testid="update-button"
      class="mt-4 ds-button-sm"
    >
      {{ $t("common.button.update") }}
    </DsButton>
  </div>
</template>

<script lang="ts">
/**
 * VersionManagerNotifier component
 * @version 1.0.0 - 2023-09-19
 */
import { defineComponent } from "vue";
import { useRegisterSW } from "virtual:pwa-register/vue";

import { DsButton } from "@devsalsa/vue-core";

const { needRefresh, updateServiceWorker } = useRegisterSW({
  onRegisteredSW(swScriptUrl, registration) {
    registration &&
      setInterval(
        async () => {
          if (!(!registration.installing && navigator)) {
            return;
          }

          if ("connection" in navigator && !navigator.onLine) {
            return;
          }

          const resp = await fetch(swScriptUrl, {
            cache: "no-store",
            headers: {
              cache: "no-store",
              "cache-control": "no-cache",
            },
          });

          if (resp?.status === 200) {
            await registration.update();
          }
        },
        60 * 60 * 1000
      );
  },
  onRegisterError(error) {
    console.log(error);
  },
});

export default defineComponent({
  name: "VersionManagerNotifier",
  components: {
    DsButton,
  },
  data() {
    return {
      loading: false,
      timeout: 0,
    };
  },
  computed: {
    needRefresh(): boolean {
      return needRefresh.value;
    },
  },
  methods: {
    onClickUpdate() {
      this.loading = true;
      clearTimeout(this.timeout);
      updateServiceWorker(true);
    },
  },
});
</script>

<style scoped lang="scss">
.version-manager-notifier {
  @apply fixed bg-white p-4 left-6 bottom-6 w-80 h-auto rounded-r-md border-l-4 border-blue-600 shadow-jb drop-shadow-jb shadow-gray-200 z-30;
}
</style>
