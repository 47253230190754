<template>
  <div
    :class="[
      'notification-list',
      { 'notification-list-disabled': disabledNavSidebar },
    ]"
  >
    <template v-if="totalRecords > 0">
      <div
        :class="[
          'notification-list-content',
          { 'notification-list-content-desktop': !isSidebar },
        ]"
      >
        <NotificationCard
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          :isSidebar="isSidebar"
          @markRead="onMarkRead"
          @delete="onDelete"
          @closeSidebar="$emit('closeSidebar')"
        />
      </div>
    </template>
    <template v-else>
      <div
        :class="[
          'notification-list-empty',
          { 'notification-list-empty-desktop': !isSidebar },
        ]"
      >
        <div class="notification-list-empty-header">
          {{ $t("modules.notification.emptyMessage.heading") }}
        </div>
        <div class="notification-list-empty-description">
          {{ $t("modules.notification.emptyMessage.description") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import NotificationCard from "@/modules/notification/components/NotificationCard.vue";

import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

export default defineComponent({
  name: "NotificationList",
  components: {
    NotificationCard,
  },
  props: {
    isSidebar: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Object as PropType<AppNotification[]>,
      required: true,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    disabledNavSidebar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeSidebar", "readNotification", "deleteNotification"],
  methods: {
    onMarkRead(notificationId: number) {
      this.$emit("readNotification", notificationId);
    },
    onDelete(notificationId: number) {
      this.$emit("deleteNotification", notificationId);
    },
  },
});
</script>

<style scoped lang="scss">
.notification-list {
  &-disabled {
    @apply flex justify-center items-center;
  }

  &-content {
    @apply relative flex-1;
  }

  &-content-desktop {
    @apply border-l border-r sm:border-l-0 sm:border-r-0 border-t;
  }

  &-empty {
    @apply min-h-[calc(100vh-149px)] relative flex flex-col items-center justify-center text-center;

    &-header {
      @apply font-bold text-gray-900 mb-2 text-lg sm:text-xl;
    }

    &-descripton {
      @apply text-gray-500 text-sm font-normal sm:text-base;
    }
  }

  &-empty-desktop {
    @apply pt-12 pb-4 border-t;
  }
}
</style>
